import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  private get document(): Document {
    return this._document;
  }

  constructor(
    @Inject(DOCUMENT) private readonly _document: any,

  ) { }

  

//  //create our event emitter to send our data to Google Analytics
//  public eventEmitter(eventCategory: string,
//   eventAction: string,
//   eventLabel: string = null,
//   eventValue: number = null) {
//     gtag('send', 'event', {
// eventCategory: eventCategory,
// eventLabel: eventLabel,
// eventAction: eventAction,
// eventValue: eventValue
// });

//   }

// public eventEmitter( 
//   eventName: string, 
//   eventCategory: string, 
//   eventAction: string, 
//   eventLabel: string = null,  
//   eventValue: number = null ){ 
//        gtag('event', eventName, { 
//         'send_to': eventCategory, 
//                'value': eventLabel, 
//                'currency': eventAction, 
//                'transaction_id': eventValue
//              })
//   }

  
public eventEmitter( 
  eventName: string, 
  eventCategory: string, 
  eventValue:string,
  eventCurrency: string,  
  transId: string ){ 
    gtag('event', eventName, { 
      'send_to': eventCategory, 
             'value': eventValue, 
             'currency': eventCurrency, 
             'transaction_id': transId
           })
  }

  public customEventEmitter( 
    eventName: string, 
    eventCategory: string, 
    eventLabel: string,  
    eventValue:string,
    ){ 
      if (typeof gtag === 'function') {
        gtag('event', eventName, { 
          'event_category': eventCategory, 
          'value': eventValue, 
          'event_label': eventLabel,
        });
      } else {
        console.error('gtag is not defined');
      }
    }


  /**
   * Send an event trigger to GA. It is the same as call:
   * ```js
   * gtag('event', 'video_auto_play_start', {
   *   'event_label': 'My promotional video',
   *   'event_category': 'video_auto_play'
   * });
   * ```
   *
   * @param action 'video_auto_play_start'
   * @param category 'video_auto_play'
   * @param label 'My promotional video'
   * @param value An value to measure something
   */
   event(action: GaActionEnum | string, category?: string, label?: string, value?: number, interaction?: boolean) {
    try {
      const opt = new Map<string, any>();

      if(action=='purchase'){
   
        opt.set('purchase', category);
      }
      else if (category) {

        
        opt.set('event_category', category);
      }
      if (label) {
        opt.set('event_label', label);
      }
      if (value) {
        opt.set('value', value);
      }
      if (interaction !== undefined) {
        opt.set('interaction', interaction);
      }
      const params = this.toKeyValue(opt);
      if (params) {
        gtag('event', action as string, params);
      } else {
       gtag('event', action as string);
      }
    } catch (error) {
      this.throw(error);
    }
  }

    private throw(err: Error) {
   
     //   console.error(err);
      
    }

  

    /** @todo Change this to `Object.fromEntity()` in the future... */
    private toKeyValue(map: Map<string, any>): { [param: string]: any } | void {
      return (map.size > 0)
        ? Array.from(map).reduce(
          (obj, [key, value]) => Object.defineProperty(obj, key, { value, enumerable: true }),
          {}
        )
        : undefined;
    }


      /**
   * Send an page view event. This is the same as
   *
   * ```js
   * gtag('config', 'GA_TRACKING_ID', {
   *   'page_title' : 'Homepage',
   *   'page_path': '/home'
   * });
   * ```
   *
   * The tracking ID is injected automatically by Inject Token NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN
   *
   * @param path /home
   * @param title Homepage
   * @param location '{ page_location }'
   * @param options '{ ... custom dimentions }'
   */
  pageView( path: string, title?: string, location?: string, options?: Object) {
    try {
      const opt = new Map<string, any>([['page_path', path]]);
      if (title) {
        opt.set('page_title', title);
      }
      if (location || this.document) {
        opt.set('page_location', (location || this.document.location.href));
      }
      if (options) {
        Object
          .entries(options)
          .map(([key, value]) => opt.set(key, value));
      }
      gtag('event','page_view', this.toKeyValue(opt));
    } catch (error) {
      this.throw(error);
    }
  }

  public emitEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
      gtag('send', 'event', {
eventCategory: eventCategory,
eventLabel: eventLabel,
eventAction: eventAction,
eventValue: eventValue
});
}
  
}


export enum GaActionEnum {
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_WISHLIST = 'add_to_wishlist',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT_PROGRESS = 'checkout_progress',
  GENERATE_LEAD = 'generate_lead',
  LOGIN = 'login',
  PURCHASE = 'purchase',
  REFUND = 'refund',
  REMOVE_FROM_CART = 'remove_from_cart',
  SEARCH = 'search',
  SELECT_CONTENT = 'select_content',
  SET_CHECKOUT_OPTION = 'set_checkout_option',
  SHARE = 'share',
  SIGN_UP = 'sign_up',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_PROMOTION = 'view_promotion',
  VIEW_SEARCH_RESULT = 'view_search_results',
  VIEW_SEARCH_RESULTS = 'view_search_results',
}
